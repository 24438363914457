import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: 'https://7b1189fb42e9d2342c8fc90d7a02ec40@o4505199616458752.ingest.us.sentry.io/4508722723946496',
  integrations: [Sentry.browserTracingIntegration()],
  // Performance Monitoring
  tracesSampleRate: environment.production ? 0.1 : 1.0, // Capture 100% of the transactions, reduce in production!
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost:4200',
    'https://recargasqr.happyland.com.pe/',
    'https://dev.client.happyland.pe.innervycs.com/',
    'https://alpha.client.happyland.pe.innervycs.com/',
  ],
  // Fijar entorno
  environment: environment.production ? 'production' : 'develop',
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
