// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  amplifySettings: {
    aws_appsync_graphqlEndpoint: 'https://wkdpde4e6fcarcbefgor6pcfry.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: 'da2-jc4midrdfzcbtmb4yndrlahxhm',
  },

  endpoints: {
    returnTo: 'https://dev.client.happyland.pe.innervycs.com',
    returnFrom: 'https://wlkd6x0xe8.execute-api.us-east-1.amazonaws.com/main',
    status: 'https://qa.status.api.happyland.pe.innervycs.com',
    secure: 'https://qa.secure.api.happyland.pe.innervycs.com',
    register: 'https://qa.register.api.happyland.pe.innervycs.com',
    // Medios de pago
    niubiz: 'https://qa.niubiz.api.happyland.pe.innervycs.com',
  },

  niubiz: {
    srcJS: 'https://static-content-qas.vnforapps.com/v2/js/checkout.js?qa=true',
    merchantId: 456879852
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
