<mat-toolbar class="fixed-toolbar">
  <button mat-icon-button (click)="toggleMenu()" aria-label="Toggle menu icon">
    <mat-icon style="color: lightgray">{{ menuOpen ? 'close' : 'menu' }}</mat-icon>
  </button>
  <span class="flex-spacer"></span>
  <img height="85%" class="navbar-logo" src="assets/images/logos/happy-plus.svg" (click)="goToPortal()" />
  <span class="flex-spacer"></span>
  <button mat-icon-button class="example-icon" disabled="true" aria-label="Search icon">
    <!-- <mat-icon style="color: lightgray">search</mat-icon> -->
  </button>
</mat-toolbar>

<div class="menu-content" *ngIf="menuOpen">
  <div class="menu-item" [hidden]="!this.appSettings.appWithLoyaltyCard" (click)="changePage('/my-happy-plus')">Mi HAPPY+</div>
  <div class="menu-item" (click)="changeToHistoricalPage('/historical', 'tickets')">HISTORIAL E-TICKETS</div>
  <div class="menu-item" (click)="changeToHistoricalPage('/historical', 'gameplay')">HISTORIAL JUGADAS</div>
  <div class="menu-item" (click)="changePage('/portal')">RECARGA</div>
  <div class="menu-item-waiver" (click)="changePage('/waiver')">
    <p class="mb-0" style="font-weight: bold; display: flex; justify-content: space-between; place-items: center">
      PASES CONSENTIMIENTO
      <img style="display: flex" [src]="'assets/images/icons/ic_file.svg'" height="30vh" alt="Logo" />
    </p>
  </div>
</div>

<div class="overlay" *ngIf="menuOpen" (click)="toggleMenu()"></div>
