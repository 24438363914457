import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app/services/app-settings/app-settings.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  menuOpen = false;

  constructor(private route: Router, public appSettings: AppSettings) {}

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  goToPortal(): void {
    this.route.navigate(['/portal']);
    if (this.menuOpen) this.toggleMenu();
  }

  changePage(src: string): void {
    this.route.navigate([src]);
    if (this.menuOpen) this.toggleMenu();
  }

  changeToHistoricalPage(src: string, type: string): void {
    this.route.navigate([src], { queryParams: { type: type } });

    if (this.menuOpen) this.toggleMenu();
  }
}
