<mat-toolbar class="fixed-toolbar">
  <button mat-icon-button (click)="toggleMenu()" aria-label="Toggle menu icon">
    <mat-icon style="color: lightgray">{{ menuOpen ? 'close' : 'menu' }}</mat-icon>
  </button>
  <span class="flex-spacer"></span>
  <img height="85%" class="navbar-logo" src="assets/images/logos/happy-plus.svg" (click)="goToPortal()" />
  <span class="flex-spacer"></span>
  <button mat-icon-button class="example-icon" disabled="true" aria-label="Search icon">
    <!-- <mat-icon style="color: lightgray">search</mat-icon> -->
  </button>
</mat-toolbar>

<div class="menu-content" *ngIf="menuOpen">
  <div class="menu-item" [hidden]="!this.appSettings.appWithLoyaltyCard" (click)="changePage('/my-happy-plus')">Mi HAPPY+</div>
  <div class="menu-item" (click)="changeToHistoricalPage('/historical', 'tickets')">HISTORIAL E-TICKETS</div>
  <div class="menu-item" (click)="changeToHistoricalPage('/historical', 'gameplay')">HISTORIAL JUGADAS</div>
  <div class="menu-item" (click)="changePage('/portal')">RECARGA</div>
</div>

<div class="overlay" *ngIf="menuOpen" (click)="toggleMenu()"></div>
