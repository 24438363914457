import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    data: { title: 'Happyland Recarga Express' },
    redirectTo: '/portal',
    pathMatch: 'full',
  },
  {
    path: '',
    children: [
      {
        path: 'card-error',
        loadChildren: () => import('./pages/error-card-page/error-card-page.module').then((m) => m.ErrorCardPageModule),
      },
      {
        path: 'server-error',
        loadChildren: () => import('./pages/error-page/error-page.module').then((m) => m.ErrorPageModule),
      },
      {
        path: 'portal',
        loadChildren: () => import('./pages/promotion-portal/promotion-portal.module').then((m) => m.PromotionPortalModule),
      },
      {
        path: 'portal/:card',
        loadChildren: () => import('./pages/promotion-portal/promotion-portal.module').then((m) => m.PromotionPortalModule),
      },
      {
        path: 'payment-page',
        loadChildren: () => import('./pages/payment-portal/payment-portal.module').then((m) => m.PaymentPortalModule),
      },
      {
        path: 'payment-complete',
        loadChildren: () => import('./pages/payment-complete/payment-complete.module').then((m) => m.PaymentCompleteModule),
      },
      {
        path: 'refund',
        loadChildren: () => import('./pages/refund-page/refund-page-routing.module').then((m) => m.RefundPageRoutingModule),
      },
      {
        path: 'niubiz/:transactionToken',
        loadChildren: () => import('./pages/niubiz-page/niubiz-page.module').then((m) => m.NiubizPageModule),
      },
      {
        path: 'my-happy-plus',
        loadChildren: () => import('./pages/my-happy-profile/my-happy-profile.module').then((m) => m.MyHappyProfileModule),
      },
      {
        path: 'historical',
        loadChildren: () => import('./pages/historical-portal/historical-portal.module').then((m) => m.HistoricalPortalModule),
      },
      {
        path: 'special-promotions',
        loadChildren: () => import('./pages/special-promotions-portal/special-promotions-portal.module').then((m) => m.SpecialPromotionsPortalModule),
      },
    ],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
