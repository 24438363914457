import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HammerModule, Title } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { HeaderComponent } from './components/header/header.component';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';
import Amplify from 'aws-amplify';
import { HttpClientModule } from '@angular/common/http';
import { ApiRestService } from './services/api-rest/api-rest.service';
import localeEsPE from '@angular/common/locales/es-PE';
import { registerLocaleData } from '@angular/common';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
registerLocaleData(localeEsPE, 'es-PE');
Amplify.configure(environment.amplifySettings);

@NgModule({
  declarations: [AppComponent, HeaderComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HammerModule,
    MatToolbarModule,
    MatButtonModule,
    MatGridListModule,
    MatIconModule,
    HttpClientModule,
    RouterModule,
    SweetAlert2Module.forRoot(),
  ],
  providers: [Title, ApiRestService, { provide: LOCALE_ID, useValue: 'es-PE' }],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private router: Router, private titleService: Title) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.titleService.setTitle('Happyland - Portal Promociones');
      }
    });
  }
}
